.fetchMessage .text {
  text-transform: capitalize;
}

.TicketsAdministration .header {
  position: relative;
  font-size: 30px;
  padding: 0.25em 0 0.25em;
  margin: 0.25em 0 0.25em 0;
  border-bottom: 1px solid lightgray;
}
.TicketsAdministration .header .title {
  font-size: 30px;
}
#unified-table {
  th,
  td {
    border-width: 1px;
  }
}
@media screen {
  .printContainer {
    height: 100%;
  }
}
.TicketsAdministration .verticalTop th {
  vertical-align: top;
}
.TicketsAdministration .TicketsTable {
  position: relative;
}
.TicketsAdministration .TicketsTable .tableHeader {
   {
    /*position:sticky;
	top:0*/
  }
}
.TicketsAdministration .TicketsTable .id,
.TicketsAdministration .TicketsTable .Name,
.TicketsAdministration .TicketsTable .Type,
.TicketsAdministration .TicketsTable .Shares,
.TicketsAdministration .TicketsTable .FreeShares,
.TicketsAdministration .TicketsTable .SharePrice,
.TicketsAdministration .TicketsTable .Actions {
  font-size: 15px;
}

.TicketsAdministration .TicketsTable td.id {
  max-width: 5ch;
}
.TicketsAdministration .TicketsTable td.Name {
  min-width: 15ch;
  max-width: 20ch;
}

.TicketsAdministration .TicketsTable td.Shares {
  max-width: 10ch;
}
.TicketsAdministration .TicketsTable td.FreeShares {
  max-width: 10ch;
}
.TicketsAdministration .TicketsTable td.SharePrice {
  max-width: 10ch;
}
.TicketsAdministration .TicketsTable td.Actions {
  max-width: 1%;
}

.Actions .iconContainer {
  cursor: pointer;
  transition: all 1s;
  color: black;
}

.Actions.disabled,
.Actions .disabled,
.Actions .iconContainer.not-allowed {
  cursor: not-allowed;
  opacity: 0.5;
  background: inherit;
  pointer-events: none;
}

.Actions.disabled .disabled,
.Actions.disabled .not-allowed {
  opacity: 1;
}

.Actions .iconContainer .icon {
  transition: all 1s;
}

.Actions .iconContainer.not-allowed .icon {
  pointer-events: none;
}

.Actions .iconContainer:not(.not-allowed):hover .icon {
  animation: ease hover 0.7s;
}
.Actions .iconContainer.red:not(.not-allowed):hover .icon {
  color: red;
}
.Actions .iconContainer.green:not(.not-allowed):hover .icon {
  color: green;
}
.Actions .verticalCenter {
  vertical-align: middle;
}
.Actions .cellWithoudPadding .form-control,
.Actions .cellWithoudPadding .form-select {
  border: 0;
  border-radius: 0;
  background-color: rgb(255 255 255 / 70%);
}

.TicketsAdministration .title {
  font-size: 25px;
  margin: 0.5em 0;
}

@media (max-width: 700px) {
  .TicketsAdministration .title {
    font-size: 18px;
  }
  .TicketsAdministration .header .title{
    font-size: 22px;
  }
}

.TicketsTable .seeMore .cellWithoudPadding {
  padding: 0;
  height: 0;
}
.TicketsAdministration {
  .react-select__control {
    border-color: #ced4da;
    border-radius: 0.375rem;
  }
  .react-select__menu {
    z-index: 3;
  }
}

.select__indicator {
  color: #666 !important;
}
