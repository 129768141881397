.FundsAdministration .searchBar {
	border-radius: 30px;
	box-shadow: 0 0 0 0 rgb(255 255 25 / 0%);
	transition: all 0.2s;
}
.FundsAdministration .searchBar .input-group-text {
	border-radius: 30px 0 0 30px;
	background: rgba(255, 255, 255, 0.7);
	border-right: none;
}
.FundsAdministration .searchBar .input-group-text .icon {
	color: #8c8a8d;
	transition: all 0.2s;
}

.FundsAdministration .searchBar .input-group-text.left {
	border-radius: 30px 0 0 30px;
	border-right: none;
	margin-right: 0px !important;
}
.FundsAdministration .searchBar .input-group-text.left .icon {
	margin-left: 0.7em;
}
.FundsAdministration .searchBar .input-group-text.right {
	border-radius: 0 30px 30px 0;
	border-left: none;
	margin-left: 0px !important;
	cursor: pointer;
	transition: all 2s;
}
.FundsAdministration .searchBar .input-group-text.right:hover .icon {
	color: rgb(250 70 0 / 50%);
}
.FundsAdministration .searchBar .input-group-text.right .icon {
	margin-right: 0.7em;
}

.FundsAdministration .searchBar .form-control {
	border-radius: 0 30px 30px 0;
	padding: 0.7em;
	border-right: none;
	border-left: none;
	background: rgba(255, 255, 255, 0.7);
}
.FundsAdministration .notfound.searchBar {
	box-shadow: 0 0 0 0.25rem rgb(200 70 0 / 25%) !important;
}
.FundsAdministration .searchBar.search {
	box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.FundsAdministration .searchBar.search .form-control {
	border-right: none;
}
.FundsAdministration .searchBar .form-control:focus {
	color: #212529;
	background: rgba(255, 255, 255, 0.7);
	border-color: rgb(206, 212, 218);
	outline: unset;
	box-shadow: unset;
}
.FundsAdministration .verticalTop th {
	vertical-align: top;
}
.FundsAdministration .FundsTable {
	position: relative;
}

.FundsAdministration .FundsTable .id,
.FundsAdministration .FundsTable .Id,
.FundsAdministration .FundsTable .Name,
.FundsAdministration .FundsTable .Type,
.FundsAdministration .FundsTable .Shares,
.FundsAdministration .FundsTable .FreeShares,
.FundsAdministration .FundsTable .SharePrice {
	font-size: 15px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	direction: rtl;
	text-align: left;
}

.FundsAdministration .FundsTable td.Id,
.FundsAdministration .FundsTable td.id {
	max-width: 10ch;
}
.FundsAdministration .FundsTable td.Name {
	min-width: 15ch;
	max-width: 20ch;
}

.FundsAdministration .FundsTable td.Shares {
	max-width: 10ch;
}
.FundsAdministration .FundsTable td.FreeShares {
	max-width: 10ch;
}
.FundsAdministration .FundsTable td.SharePrice {
	max-width: 10ch;
}
.FundsAdministration .FundsTable td.Actions {
	max-width: 1%;
}

.table .add  {
	border: none;
}
.table .add  td {
	border: none;
}
.table .add .Actions {
	box-shadow: inset 0px 0px 0px 1px grey;
	background-color: rgba(0, 0, 0, 0.025);
}

.table .add .cellWithoudPadding {
	padding: 0;
	height: 0;
}
.FundsAdministration .fundRow .verticalCenter {
	vertical-align: middle;
}
.FundsAdministration .fundRow .cellWithoudPadding .form-control,
.FundsAdministration .fundRow .cellWithoudPadding .form-select {
	border: 0;
	border-radius: 0;
	background-color: rgb(255 255 255 / 70%);
}
.deleteModal .hidden {
	position: absolute;
	left: 0;
	right: 0;
	opacity: 0;
	transition: ease opacity 0.5s;
}
.deleteModal .show {
	position: absolute;
	left: 0;
	right: 0;
	opacity: 1;
	transition: ease 0.5s opacity 0.5s;
}
.deleteModal .placeHolder {
	pointer-events: none;
	opacity: 0;
}
.deleteModal .header,
.deleteModal .body,
.deleteModal .footer {
	background-color: white !important;
}
.deleteModal .body {
	border-radius: 30px 30px 0 0;
}

.deleteModal .title {
	color: black !important;
	text-align: center;
	font-weight: 300;

	font-size: 40px;
}
.FundsAdministration .editResult,
.FundsAdministration .editForm,
.FundsAdministration .ViewDeleteAndCreateFunds {
	animation: ease growOpacity 1s;
}

.deleteModal .descriptionIconContainer,
.editResult .descriptionIconContainer {
	font-size: 60px;
	width: 1.5em;
	height: 1.5em;
	border: 3px solid;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.deleteModal .descriptionIconContainer.red,
.editResult .descriptionIconContainer.red {
	border-color: red;
}
.deleteModal .descriptionIconContainer.green,
.editResult .descriptionIconContainer.green {
	border-color: green;
}

.deleteModal .icon,
.editResult .icon {
	text-align: center;
	font-size: 60px;
}
.deleteModal .icon.red,
.editResult .icon.red {
	color: red !important;
}
.deleteModal .icon.green,
.editResult .icon.green {
	color: green !important;
}
.deleteModal .subTitle,
.editResult .subTitle {
	color: black !important;
	font-weight: 500;
	text-align: center;
	font-size: 20px;
}
.deleteModal .heading,
.editResult .heading {
	color: black !important;
	text-align: center;
	font-weight: 300;
	font-size: 15px;
}
.FundsAdministration .editForm .header,
.FundsAdministration .editResult .header {
	position: relative;
	font-size: 30px;
	padding: 0.5em 0;
	margin: 0.5em 0;
	border-bottom: 1px solid;
}
.FundsAdministration .editForm .header .title,
.FundsAdministration .editResult .header .title {
	font-size: 30px;
	font-weight: 300;
	padding: 0;
	margin: 0;
}
.FundsAdministration .editForm .header .button,
.FundsAdministration .editResult .header .button {
	font-size: 20px;
	position: absolute;
	bottom: 0;
	left: 1ch;
	transform: translateY(50%);
	cursor: pointer;
	transition: 0.5s all;
}
.FundsAdministration .editForm .header .button.icon,
.FundsAdministration .editResult .header .button.icon {
	font-size: 20px;
	color: var(--blue-main);
	background-color: lightgrey;
	border-radius: 50%;
}
.FundsAdministration .editForm .header .button.icon:hover,
.FundsAdministration .editResult .header .button.icon:hover {
	animation: ease bounceLeft 0.5s 2;
}

@keyframes bounceLeft {
	0%,
	100% {
		transform: translateY(50%) translateX(0%);
	}
	50% {
		transform: translateY(50%) translateX(-25%);
	}
}
.FundsAdministration .editForm .littleSpinner {
	width: 1em !important;
	height: 1em !important;
}
.firstWithouthPadding .containerCard:first-of-type {
	padding-left: 0;
}
.fundLogo {
	font-size: 30px;
	height: 4em;
	width: 4em;

	background: #082044;
	display: flex;
	align-items: center;
	justify-content: center;
}
.fundLogo .border {
	font-size: 30px;
	border: white 1px solid;
	border-radius: 50%;
	height: 2.7em;
	width: 2.7em;
	margin: 0.3em 0em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.fundLogo .logo {
	width: 30px;
	display: block;
}

.inputSearchLogo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.inputSearchLogo.active {
	opacity: 1;
	transition: 0.4s all 0.2s;
}

.inputSearchLogo.hidden {
	opacity: 0;
	transition: 0.2s all 0.2s;
}

.inputSearchLogo.placeholder {
	position: static;
	opacity: 0;
	cursor: unset;
}
