.login {
	background-color: rgb(248 248 249);
	background-repeat: no-repeat;
	background-size: cover;
}
.login .languageSelectorContainer{
	position: absolute;
	left: 1rem;
	top:.75rem
}	
.login .card {
	background-color: transparent;
	border-radius: 30px;
	border: none;
}
.login .card .card-body {
	background-color: white;
	border-radius: 30px 30px 0 0;
	border-bottom: white 20px solid;
}
.login .forgotPass {
	text-align: center;
	background-color: transparent !important;
	border: none;
}
.login .form-check-input:checked {
	background-color: var(--main-color);
	border-color: var(--secondary-color);
}
.login .error {
	color: var(--error);
	font-size: 14px;
	font-weight: bolder;
	text-align: end;
}

.login .button {
	border-top: gray 3px solid;
	text-transform: uppercase;
	border-radius: 0 0 30px 30px;
	transition: border-radius 0.5s;
	background-color:var(--blue-main)!important;
	border-color:#1379e7!important
}
.login .button:hover {
    background-color: #0b89f2!important;
}

.login .card-footer {
	padding: 0;
	border-radius: 0 0 30px 30px;
}
.login .image {
	display: block;
	width: 100%;
}

.login .reduced {
	width: 70%;
}
.login .card-img {
	width: 20%;
}
.login .input-group-text {
	background-color: white;
	border-right: none;
	padding-left: 1rem;
	border-radius: 30px;
}
.login .form-control {
	border-left: none;
	border-radius: 0 30PX 30PX 0px;
}
.login .mobile {
	margin-top: 4em;
}
@media (max-width: 576px) {
	.login {
		background-color: rgb(248 248 249);
		background-repeat: no-repeat;
		background-size: auto 106%;
		background-position: 10% center;
	}
	.login .button {
		border-radius: 30px 30px 30px 30px;
		border-top: none;
	}
	.login .card-img {
		width: 30%;
	}
}
@media (min-width: 576px) {
	.login .button {
		border-radius: 0 0 30px 30px;
	}
	.login .card-img {
		width: 30%;
	}
	
}
