$color_1: white;
$color_2: black;
$color_3: dimgray;
$color_4: var(--blue-main);
$background-color_1: transparent;
$background-color_2: var(--blue-main);
$background-color_3: white;
$background-color_4: var(--main-card-color);
$border-color_1: var(--blue-main);
$border-color_2: var(--main-card-color);
$border-color_3: white;

.cardsContainer {
	@media (min-width: 576px) {
		max-height: 90%;

		.row {
			max-height: 100%
		}
	}

	.FundCard {
		border: 1px transparent;
		border-radius: 30px;
		background-color: $background-color_1;
		filter: drop-shadow(0px 0px 3px gray);

		.header {
			border-radius: 30px 30px 0px 0px;
			border: 1px solid;
			border-color: $border-color_1;
			font-size: 60px;
			background-color: $background-color_2;

			.currencyContainer {
				>.currency {
					font-size: 30px;
					border: transparent;
					background-color: $background-color_1;
					color: $color_1;
					margin: 0;
					padding: 0;
					width: 30px;
				}

				font-size: 30px;
				border: white 1px solid;
				border-radius: 50%;
				height: 2.7em;
				width: 2.7em;
				margin: .3em 0em;
			}
		}

		.body {
			border-left: 1px solid;
			border-right: 1px solid;
			border-bottom: 1px solid;
			border-color: $border-color_1;
			background-color: $background-color_3;

			.title {
				color: $color_2;
				text-transform: uppercase;
				text-align: start;
				font-size: 20px;
			}

			.title-gray {
				text-transform: capitalize;
				color: $color_3;
				text-align: start;
				font-size: 20px;
			}

			.subTitle {
				color: $color_2;
				text-align: start;
				font-size: 17px;
			}

			.balanceAmount {
				padding: 0;
				text-align: end;
				font-size: 20px;
				color: $color_3;
			}

			.balance {
				padding: 0;
				float: right;
				font-size: 17px;
			}
		}

		.footer {
			background-color: $background-color_1;
			border: none;
			border-radius: 0px 0px 30px 30px;
			border-color: $border-color_2;

			.button {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 2em;
				font-size: 30px;
				background-color: $background-color_3;
				border: 1px solid;
				border-color: $border-color_1;
				color: $color_4;
				width: 100%;

				.label {
					font-size: 20px;
					margin: 0;
				}

				&:disabled {
					background-color: $background-color_3 !important;
					opacity: .9;
					cursor: not-allowed;
				}
			}

			.button.disabled {
				background-color: $background-color_3 !important;
				opacity: .9;
				cursor: not-allowed;
			}

			.left {
				border-radius: 0px 0px 0px 30px;
			}

			.right {
				border-radius: 0px 0px 30px 0px;
			}
		}
	}

	.card-body {
		padding: calc(var(--bs-card-spacer-y) / 2) var(--bs-card-spacer-x);
	}

	.FundCard.FixedDeposit {
		.card-body {
			border-bottom: transparent !important;
		}

		.card-footer {
			margin-top: 0;
			min-height: 40px;
			background-color: white;
			border-left: 1px solid;
			border-right: 1px solid;
			border-bottom: 1px solid;
			border-color: var(--blue-main);
		}
	}
}

.cashCard {
	.body {
		.title {
			color: $color_2;
			text-transform: uppercase;
			text-align: start;
			font-size: 20px;
		}
	}
}

.lighter {
	font-weight: lighter;
	font-weight: lighter;
}

.bolder {
	font-weight: bolder;
}

.modal:not(.exchange-modal) {
	.modal-content {
		background-color: $background-color_1;
		border: transparent;
	}

	.header {
		background-color: $background-color_4;
		border-color: $border-color_3;
		border-radius: 30px 30px 0 0;
		border-color: $border-color_3;

		.title {
			color: $color_1;
		}
	}

	.body {
		background-color: $background-color_4;
		border-color: $border-color_3;

		.help {
			color: $color_1;
		}
	}

	.footer {
		background-color: $background-color_4;
		border-color: $border-color_3;
		border-radius: 0 0 30px 30px;
		border-color: $border-color_3;
	}
}

@media (max-width: 576px) {
	.fund-col {
		&:first-of-type {
			margin-top: 1rem;
		}

		padding-left: 1rem;
		padding-right: 1rem;
		margin-bottom: 1rem;
	}
}


@media (max-width: 576px) {
	.FundCard {
		.header {
			.currencyContainer {
				>.currency {
					font-size: 20px !important;
					width: 20px !important;
				}

				font-size: 20px !important;
			}
		}

		.body {
			.title {
				font-size: 16px !important;
			}

			.title-gray {
				font-size: 18px !important;
			}

			.subTitle {
				font-size: 14px !important;
			}
		}

		.footer {
			.button {
				height: 1.5em !important;

				.label {
					font-size: 14px !important;
				}
			}
		}
	}
}