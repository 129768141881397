.secondaryColor {
	background-color: var(--secondary-color);
}
.DashBoard {
	background-color: rgb(248, 248, 248);
	background-repeat: no-repeat;
	background-size: cover;
}
.toggler-mobile {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.toggler-mobile:not(.loading):after {
	display: inline-block;
	margin-left: .2125em;
	vertical-align: .125em;
	content: "";
	margin-right: 7px;
	background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23082044%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>");
	width: 10px;
	height: 10px;
	transition: all 0.5s;
}

.offcanvas{
	--bs-offcanvas-height: 70vh!important;
	height: unset!important;
	max-height: var(--bs-offcanvas-height);
}

.toggler-mobile.toggled:after {
	transform:rotate(180deg)
}

.dropdown-toggle::after {
	vertical-align: 0em;
	background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23082044%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>");
	border: none!important;
	width: 10px;
	height: 10px;
}


.form-check-input:checked {
	background-color: var(--main-color);
	border-color: var(--main-color);
}

.mainBody {
	height: calc(100vh - 130px);
}

.disabled {
	background-color: grey;
}

.page-item.active .page-link {
	background-color: var(--main-color);
	border-color: var(--secondary-color);
	transition: all 0.3s;
}
.page-link {
	color: var(--main-color);
	transition: all 0.3s;
}
.page-link:hover {
	color: var(--secondary-color);
}
.page-link:focus {
	color: black;
}
.cardText {
	text-align: start;
}
.currency {
	background-color: var(--main-color);
	border: 1px solid;
	border-radius: 4px;
	font-size: 12px;
	text-align: center;
}
.sideBarDiv {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: #fff;
}
.overlayTrigger {
	float: right;
}
.toTransaction {
	border-bottom: 1px solid;
	border-color: var(--main-color);
	display: block;
}
.loadingText {
	font-size: 30px;
}
h1 {
	font-size: 30px;
	margin-top: 1rem;
}
h2 {
	font-size: 20px;
	text-align: start;
	margin: 0;
}
h3 {
	font-size: 30px;
	text-align: start;
	margin-top: 1rem;
}
h5 {
	font-size: 1.25rem;
}

.transactionInfo .icon {
	text-align: center;
	color: green;
	font-size: 80px;
}
.transactionInfo .textWithBottomDivider {
	font-size: 18px;
	border-bottom: 1px solid;
	border-color: #dfdfdf;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	opacity: 0.8;
}
.transactionInfo .title {
	font-size: 30px;
}
.transactionInfo .date {
	font-size: 20px;
	opacity: 0.7;
}
.transactionInfo .listedInfo {
	font-size: 18px;
	opacity: 0.8;
}
.transactionInfo .accordion-body {
	padding: 0;
}

/*.dropdown-toggle{
    padding-top: 0;
    padding-bottom: 0;
    font-size: x-large;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0;
    vertical-align: 0;
    content: "";
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}*/
.nav-link {
	color: gray;
}
.nav-link:focus,
.nav-link:hover {
	color: gray;
}
.notHover {
	pointer-events: none;
}

/*Pantallas muy grandes  (desktops de más de 1200px de ancho)
No hace falta media-query porque será nuestro diseño por defecto*/
/*Pantallas extra grandes (desktops de mas de 1200px) xl X≥1200px*/
@media (min-width: 1201px) {
	h1 {
		font-size: 30px;
	}
	h2 {
		font-size: 20px;
	}
	.h5 {
		font-size: 1.25rem;
	}
	.cardTextMain {
		height: calc(100vh - 130px);
	}
	.accountsContainerWidth {
		width: 75%;
	}
}
/*Pantallas grandes (desktops de menos de 1200px) lg 1200px>X≥992px*/
@media (max-width: 1200px) {
	h1 {
		font-size: 30px;
	}
	h2 {
		font-size: 18px;
	}
	.h5 {
		font-size: 18px;
	}
	.cardTextMain {
		height: calc(100vh - 130px);
	}
}

/*Pantallas medianas (tablets de menos de 992px) md 992px>X≥768px;*/

@media (max-width: 992px) {
	h1 {
		font-size: 25px;
	}
	.cardTextMain {
		height: calc(100vh - 130px);
	}
	.sideToSidebar {
		margin-left: 64px;
	}
}

/*Pantallas pequeñas (móviles en landscape de menos de 768px)  sm 768px>X≥576px*/

@media (max-width: 768px) {
	h1 {
		font-size: 25px;
	}
	.cardTextMain {
		height: calc(100vh - 130px);
	}
}

/*Pantallas muy pequeñas (móviles en portrait de menos de 576px) xs 576px>X*/

@media (max-width: 576px) {
	h1 {
		font-size: 25px;
	}
	.cardTextMain {
		height: auto;
	}
	.cardTextMain {
		height: calc(100vh - 130px);
	}
}
