.notFund .icon{
    height: 100%;
    width: 100%;
}
.notFund .title{
    text-align: center;
    font-size: 70px;
}
.notFund .description{
    font-size: 30px;
    text-align: center;
}