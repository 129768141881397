.OperationStatus .title{
    color:green;
    text-align: center;
    font-size: 40px;

}
.OperationStatus .description{
    color:green;
    text-align: center;
    font-size: 20px;

}
.OperationStatus .statusIcon{
    text-align: center;
    font-size: 100px;
    color:green
}

.OperationStatus.failed .title,
.OperationStatus.failed .description,
.OperationStatus.failed .statusIcon {
  color: red;
}